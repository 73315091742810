import { Link } from "gatsby"
import React from "react"
import {
  COURSES,
  EBOOKS,
  IMAGES_APPS,
  IMAGES_APP_PRODUCTIVITY,
} from "../../../constants"
import { useLanguage, useParams } from "../../../hooks"
import { t } from "../../../utils/t"
import {
  Main,
  RowAndSpaceBetween,
  SubTitleSection,
  Text,
  TitleMain,
  Label,
} from "../../atomics"
import Section from "../../Section"
import Page from "../Page"

function Home(props) {
  const { language } = useLanguage("pt")
  const { query } = useParams()

  const sendEvent = event => {
    if (window) {
      if (window.fbq) window.fbq("track", event)
      if (window.gtag) window.gtag("event", event)
    }
  }

  return (
    <Page {...props}>
      <Main>
        <div>
          <TitleMain>{t("main_title", language)}</TitleMain>
          <Text style={{ marginTop: 10 }}>{t("main_desc", language)}</Text>
        </div>
        <img src={IMAGES_APPS.main} alt="aplicativo" className="img-main" />
      </Main>

      <Section title={t("ours_apps", language)} id="apps">
        <div>
          <RowAndSpaceBetween>
            <div style={{ marginTop: 40, minWidth: 330 }}>
              <SubTitleSection>
                {t("flynow_productivity", language)}
              </SubTitleSection>
              <Text style={{ marginTop: 20, fontSize: "1.1rem" }}>
                {t("flynow_productivity_desc", language)}
              </Text>
              <Link
                className="button-vermais"
                to={"/apps/productivity" + query}
                onClick={() => sendEvent("see_app_prod")}
              >
                {t("see_more", language)}
              </Link>
            </div>
            <img
              src={IMAGES_APP_PRODUCTIVITY.main}
              alt="aplicativo"
              className="img-func"
            />
          </RowAndSpaceBetween>
        </div>

        <div style={{ marginTop: 20 }}>
          <RowAndSpaceBetween>
            <div style={{ marginTop: 40, minWidth: 330 }}>
              <SubTitleSection>
                {t("flynow_finances", language)}
              </SubTitleSection>
              <Text style={{ marginTop: 20, fontSize: "1.1rem" }}>
                {t("flynow_finances_desc", language)}
              </Text>
              <Link
                className="button-vermais"
                to={"/apps/finances" + query}
                onClick={() => sendEvent("see_app_finances")}
              >
                {t("see_more", language)}
              </Link>
            </div>
            <img
              src={require("../../../assets/images/app/finances/main.png")}
              alt="aplicativo"
              className="img-func"
            />
          </RowAndSpaceBetween>
        </div>

        <div style={{ marginTop: 20, marginBottom: 50 }}>
          <RowAndSpaceBetween>
            <div style={{ marginTop: 40, minWidth: 330 }}>
              <SubTitleSection>{t("flynow_frases", language)}</SubTitleSection>
              <Text style={{ marginTop: 20, fontSize: "1.1rem" }}>
                {t("flynow_frases_desc", language)}
              </Text>
              <Link
                className="button-vermais"
                to={"/apps/phrases" + query}
                onClick={() => sendEvent("see_app_phrases")}
              >
                {t("see_more", language)}
              </Link>
            </div>
            <img
              src={require("../../../assets/images/app/frases/app-frases.png")}
              alt="aplicativo"
              className="img-func"
            />
          </RowAndSpaceBetween>
        </div>
      </Section>

      {props.showCourses && (
        <Section title={"NOSSOS CURSOS"} id="cursos">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              // justifyContent: "space-between",
              justifyContent: "center",
              marginBottom: "2rem",
            }}
          >
            {COURSES.map(post => (
              <Link
                to={post.url + query}
                onClick={() => sendEvent("see_" + post.id)}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: 300,
                    marginTop: "1rem",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={post.img}
                    style={{ height: 250, marginBottom: 10 }}
                    alt={post.title}
                  />
                  <Label>{post.title}</Label>
                  <Text style={{ marginTop: 5, fontSize: "1.1rem" }}>
                    {post.description}
                  </Text>
                </div>
              </Link>
            ))}
          </div>
        </Section>
      )}

      {props.showEbooks && (
        <Section title={"NOSSOS EBOOKS"} id="ebooks">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "2rem",
            }}
          >
            {EBOOKS.map(post => (
              <Link
                to={post.url + query}
                onClick={() => sendEvent("see_" + post.id)}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: 300,
                    marginTop: "1rem",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={post.img}
                    style={{ height: 250, marginBottom: 10 }}
                    alt={post.title}
                  />
                  <Label>{post.title}</Label>
                  <Text style={{ marginTop: 5, fontSize: "1.1rem" }}>
                    {post.description}
                  </Text>
                </div>
              </Link>
            ))}
          </div>
        </Section>
      )}
    </Page>
  )
}

export default Home
